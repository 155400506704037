import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [];

export const dictionary = {
		"/(authenticated)/(architect)/(app)": [8,[2,3,4]],
		"/404": [30],
		"/(authenticated)/(architect)/(app)/apps": [9,[2,3,4]],
		"/(authenticated)/(architect)/(app)/apps/new": [10,[2,3,4]],
		"/(authenticated)/(architect)/(app)/attributes": [11,[2,3,4,5]],
		"/(authenticated)/(architect)/(app)/attributes/[id]": [12,[2,3,4,5]],
		"/confirm": [31],
		"/(lobby)/forgot-password": [24,[7]],
		"/(authenticated)/(architect)/(app)/import": [13,[2,3,4]],
		"/(lobby)/login": [25,[7]],
		"/(lobby)/openid-redirect": [26,[7]],
		"/(authenticated)/(architect)/(app)/publications": [14,[2,3,4]],
		"/publication/[id]": [32],
		"/(lobby)/reset-password": [27,[7]],
		"/(authenticated)/(architect)/(app)/settings": [15,[2,3,4]],
		"/(lobby)/signup": [28,[7]],
		"/(authenticated)/(architect)/(app)/studio": [16,[2,3,4]],
		"/(lobby)/team-select": [29,[7]],
		"/(authenticated)/(architect)/(app)/team": [17,[2,3,4,6]],
		"/(authenticated)/(architect)/(app)/team/checkout-success": [18,[2,3,4,6]],
		"/(authenticated)/(architect)/(app)/team/groups": [19,[2,3,4,6]],
		"/(authenticated)/(architect)/(app)/team/groups/[groupId]": [20,[2,3,4,6]],
		"/(authenticated)/(architect)/(app)/team/plans": [21,[2,3,4,6]],
		"/(authenticated)/(architect)/(app)/team/users": [22,[2,3,4,6]],
		"/(authenticated)/(architect)/[type=app_or_block]/[id]": [23,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.svelte';